<template>
  <div class="journalismDetails">
    <TopNavBar />
    <div class="box">
      <el-breadcrumb separator=">>">
        <el-breadcrumb-item :to="{ path: '/journalism' }"
          >新闻动态</el-breadcrumb-item
        >
        <el-breadcrumb-item>新闻详情</el-breadcrumb-item>
      </el-breadcrumb>
      <div class="journalism_box">
        <div class="left">
          <h4>{{ data.dates[2] }}</h4>
          <span>{{ data.dates[0] + "-" + data.dates[1] }}</span>
          <div>行业新闻</div>
        </div>
        <div class="right">
          <h3>{{ data.title }}</h3>
          <div v-html="data.content"></div>
        </div>
      </div>
    </div>

    <Footer />
  </div>
</template>

<script>
export default {
  name: "JournalismDetails",
  data() {
    return {
      data: {
        title: this.$route.query.newsTitle,
        date: this.$route.query.newsDate,
        content: this.$route.query.newsContent,
      },
    };
  },
  created() {
    this.data.dates = this.data.date.split("-");
  },
  mounted() {},
};
</script>

<style scoped lang="scss">
.journalismDetails {
  .box {
    padding: 70px 100px 60px;
    ::v-deep .el-breadcrumb {
      .el-breadcrumb__item {
        .el-breadcrumb__inner,
        .el-breadcrumb__separator {
          font-size: 20px;
          font-weight: 200;
          line-height: 90px;
          color: #333;
        }
        .el-breadcrumb__separator {
          font-family: "AlibabaPuHuiTi-Light";
          margin: 0 12px;
        }
      }
    }
    .journalism_box {
      margin-top: 10px;
      display: flex;
      justify-content: space-between;
      .left {
        min-width: 140px;
        height: 140px;
        margin-right: 40px;
        border: 1px solid #eeeeee;
        border-radius: 5px;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        h4 {
          font-weight: 400;
          font-size: 25px;
          color: #333;
          font-family: "AlibabaPuHuiTi-Regular";
          line-height: 40px;
        }
        span {
          font-size: 18px;
          color: #666;
          font-family: "AlibabaPuHuiTi-Light";
          font-weight: 200;
          line-height: 40px;
        }
        div {
          margin-top: 5px;
          height: 40px;
          background-color: #006eff;
          font-weight: 200;
          font-size: 18px;
          color: #ffffff;
          line-height: 40px;
          border-radius: 0 0 5px 5px;
        }
      }
      .right {
        width: 100%;
        h3 {
          font-weight: 400;
          font-size: 25px;
          line-height: 40px;
          text-align: left;
          margin: 5px 0 15px;
        }
        div {
          text-align: left;
          font-weight: 200;
          font-size: 20px;
          color: #666666;
          line-height: 40px;
        }
      }
    }
  }
}
</style>
